import { message } from "antd";
import axios from "axios";

// const initAxios = () => {
//   axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
//   axios.defaults.withCredentials = true;
//   axios.defaults.headers.post["Content-Type"] = "application/json";
//   axios.defaults.headers.post["Accept"] = "application/json";
//   axios.defaults.timeout = 2500;
//   axios.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     (error) => {
//       if (error.response && error.response.status와 === 401) {
//         // 여기서 사용자에게 알림을 표시하거나, 로그인 페이지로 리다이렉트 할 수 있습니다.
//         return Promise.reject(new Error("세션이 만료되었습니다. 다시 로그인해주시기 바랍니다."));
//       }
//       // 그 외 에러는 그대로 반환
//       return Promise.reject(error);
//     }
//     // 401 에러가 발생하면 401 status와 함께 에러 메시지로 '세션이 만료되었습니다. 다시 로그인해주시기 바랍니다.'를 반환하고
//     // 그외에는 return 하게 해줘
//   );
// };

const initAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.timeout = 30000; // 30(초) 타임아웃
};

const api = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  timeout: 2500,
});
api.interceptors.response.use(
  (response) => response, // 성공 응답 처리
  (error) => {
    // 401 에러 감지
    // if (error.response && error.response.status === 401) {
    //   navigate("/authentication/sign-in/basic"); // 로그인 페이지로 리다이렉션
    // }

    if (error.response) {
      switch (error.response.status) {
        case 401:
          message.error("세션이 만료되었습니다. 다시 로그인해주시기바랍니다.");
          // window.location.href = "/authentication/sign-in/basic";
          window.location.replace("/authentication/sign-in/basic");
          // navigate("/authentication/sign-in/basic", { replace: true }); // 로그인 페이지로 리다이렉션
          break;
        case 498:
          break;

        case 477:
          message.error(`${error.response.data}`);
          break;
        case 500:
          message.error(`${error.response.data.message ?? ""}`);
          // 여기에 500 에러에 대한 추가적인 처리를 할 수 있습니다.
          break;
        default:
          message.error(`${error.response.data.message}`);
        // console.log(`Error ${error.response.status}: ${error.response.statusText}`);
        // 기타 오류에 대한 처리
      }
    } else {
      // 오류 응답이 없는 경우 (네트워크 오류 등)
      // notification.error({
      //   message: "An error occurred, but the server did not respond",
      //   description: error.response.data.message ?? "",
      //   placement: "topRight",
      // });
      message.error(`${error.response.data.message}`);
    }

    return Promise.reject(error);
  }
);

export { initAxios, api };
