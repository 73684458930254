import { Checkbox, Modal, Spin, Table, message } from "antd";
import Apis from "apis/remotes";
import { IOfferUser, IWorkById } from "apis/response";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Work from "./components/Work";
import MDButton from "components/MDButton";

import { tableColumn } from "./components/data";
import UserSearchForm from "./components/UserSearchForm";

function SpecialOffer(): JSX.Element {
  const { id } = useParams();

  const [announcement, setAnnouncement] = useState<IWorkById>();

  const [loading, setLoading] = useState(false);

  const [searchFilter, setSearchFilter] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [users, setUsers] = useState<IOfferUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IOfferUser[]>([]);

  const [showModal, setShowModal] = useState(false);

  // Submit special offer
  // 100개씩 나눠서 보내기 Promise
  // const handleSubmitOffer = async () => {
  //   if (selectedUsers.length === 0) {
  //     message.warning("Please select at least one user.");
  //     return;
  //   }
  //   try {
  //     setLoading(true);
  //     const userIds = selectedUsers.map((user) => ({ userId: user.userId }));
  //     await Apis.postOffer({
  //       workId: announcement?.workId,
  //       title: announcement?.title,
  //       data: userIds,
  //     });
  //     message.success("성공적으로 특별 제안이 전송되었습니다.");
  //   } catch (error) {
  //     console.error("Error submitting offer:", error);
  //   } finally {
  //     setShowModal(false);
  //     setLoading(false);
  //   }
  // };

  const handleSubmitOffer = async () => {
    if (selectedUsers.length === 0) {
      message.warning("Please select at least one user.");
      return;
    }

    // Utility to split array into chunks
    const chunkArray = (array: any[], chunkSize: number) => {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    };

    try {
      setLoading(true);

      // Split userIds into chunks of 100
      const userIds = selectedUsers.map((user) => ({ userId: user.userId }));
      const batches = chunkArray(userIds, 100);

      // Use Promise.all to send all batches in parallel
      await Promise.all(
        batches.map((batch, index) =>
          Apis.postOffer({
            workId: announcement?.workId,
            title: announcement?.title,
            data: batch,
          }).then(() => {
            // message.info(`Batch ${index + 1}/${batches.length} sent successfully.`);
          })
        )
      );

      // If all batches succeed
      message.success("성공적으로 특별 제안이 전송되었습니다.");
    } catch (error) {
      console.error("Error submitting offer:", error);
      // message.error("An error occurred while sending batches. Please try again.");
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    Apis.getWorkById(id)
      .then((res) => {
        setAnnouncement(res.data);
      })
      .catch((err) => {
        console.log(err.response.data.message ?? "");
      });
  }, [id]);

  useEffect(() => {
    if (!announcement) {
      return;
    }
    Apis.getOfferUser({
      workId: id,
      ...searchFilter,
      workDay: announcement?.workDay,
    })
      .then((res) => {
        setUsers(res.data.data);
        setTotal(res.data.total);
        setSelectedRowKeys([]);
        setSelectedUsers([]);
      })
      .catch((err) => {
        console.log(err.response.data.message ?? "");
      });
  }, [id, searchFilter, announcement]);

  const handleSelect = (record: IOfferUser, selected: boolean) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.userId]);
      setSelectedUsers((users) => [...users, record]);
    } else {
      setSelectedRowKeys((keys) => keys.filter((key) => key !== record.userId));
      setSelectedUsers((users) => users.filter((user) => user.userId !== record.userId));
    }
  };

  const toggleSelectAll = () => {
    if (selectedRowKeys.length === users.length) {
      setSelectedRowKeys([]);
      setSelectedUsers([]);
    } else {
      const allSelectedKeys = users.map((r) => r.userId);
      setSelectedRowKeys(allSelectedKeys);
      setSelectedUsers(users);
    }
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length === users.length && selectedRowKeys.length > 0}
      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < users.length}
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox" as const, // Explicitly set the type to "checkbox"
    fixed: true,
    onSelect: handleSelect,
    columnTitle: headerCheckbox,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Work announcement={announcement} />
      <UserSearchForm setFormData={setSearchFilter} />

      <Table
        rowSelection={rowSelection}
        pagination={{
          position: ["bottomLeft"],
          pageSize: 10,
          total: total,
          showSizeChanger: false,
        }}
        dataSource={users}
        rowKey={(record) => record.userId}
        scroll={{ x: "max-content" }}
        columns={tableColumn}
        footer={() => (
          <MDBox display={"flex"} justifyContent={"space-between"}>
            <MDBox>
              <MDButton variant="gradient" color="info" onClick={() => setShowModal(true)}>
                전송
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      />

      <Modal
        title="특별제안 미리보기"
        width={"60%"}
        open={showModal}
        onCancel={() => setShowModal(false)}
        okButtonProps={{
          disabled: loading,
        }}
        onOk={() => {
          handleSubmitOffer();
          // setShowModal(false);
        }}
      >
        <Spin spinning={loading} size="large">
          <Work announcement={announcement} />
          <Table
            pagination={{ pageSize: 10, showSizeChanger: false }}
            dataSource={selectedUsers}
            rowKey={(record) => record.userId}
            scroll={{ x: "max-content" }}
            columns={tableColumn}
          />
        </Spin>
      </Modal>
    </DashboardLayout>
  );
}

export default SpecialOffer;
